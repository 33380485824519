<template>
    <v-container>
            <v-row justify="center" class="py-5">
                <h1 class="display-1 font-weight-light">View User</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="px-10 pb-5">
                        <v-card-text class="title text-center">Search Criteria</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-btn class="blue darken-2" @click="search">Search</v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-if="isDataAvailable">
                <v-data-table dense :headers="headers" :items="userList" item-key="id">
                </v-data-table>
            </v-row>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    components: {
    },
    data() {
        return {
            userId: null,
            view: null,
            headers: [
                {
                    text: 'id',
                    value: 'id',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    divider: true,
                },
                {
                    text: 'name',
                    value: 'name',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    divider: true,
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isDataAvailable() {
            return this.userId && this.data;
        },
    },
    methods: {
        async fetch() {
            console.log('fetching...');
            const view = await this.$client.data.view({ table: 'user', id: this.userId });
            console.log(`data: ${JSON.stringify(view)}`);
            this.view = view;
        },
    },
    created() {
        this.userId = this.$route.query.id;
    },
};
</script>
